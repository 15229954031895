import styled from "styled-components"
import BannerBG from "../../../images/banner-texture.png"

export const ContainerWrapper = styled.div`
    padding-top: 105px;
`;

export const LayeredDivWrapper = styled.div`
    position: relative;
    max-width: 1400px;
    margin: auto;

    .header {
      text-align: center;
      font-size: 36px;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: -0.025em;
      padding: 20px 0 0 0;
    }

    .content {
      font-size: 20px;
      font-weight: 350;
      line-height: 1.2;
      letter-spacing: -0.025em;
      padding: 15px;
    }

    @media (min-width: 1400px) {
        margin-bottom: 1025px;
    }

    @media (min-width: 1300px) and (max-width: 1399px) {
        margin-bottom: 1075px;
    }

    @media (min-width: 1200px) and (max-width: 1299px) {
        margin-bottom: 1100px;
    }

    @media (min-width: 1150px) and (max-width: 1199px) {
      margin-bottom: 1175px;
  }

    @media (min-width: 1100px) and (max-width: 1149px) {
        margin-bottom: 1225px;
    }

    @media (min-width: 991px) and (max-width: 1099px) {
      margin-bottom: 1225px;
    }

    @media (min-width: 925px) and (max-width: 990px) {
      margin-bottom: 1500px;
    }

    @media (min-width: 850px) and (max-width: 924px) {
      margin-bottom: 1550px;
    }

    @media (min-width: 775px) and (max-width: 849px) {
      margin-bottom: 1600px;
    }

    @media (min-width: 700px) and (max-width: 774px) {
      margin-bottom: 1675px;
    }

    @media (min-width: 600px) and (max-width: 699px) {
      margin-bottom: 1800px;
    }

    @media (min-width: 500px) and (max-width: 599px) {
      margin-bottom: 1950px;
    }

    @media (min-width: 415px) and (max-width: 499px) {
      margin-bottom: 2000px;
    }

    @media (min-width: 376px) and (max-width: 414px) {
      margin-bottom: 2175px;
    }

    @media (min-width: 321px) and (max-width: 375px) {
      margin-bottom: 2350px;
    }

    @media (max-width: 320px) {
      margin-bottom: 2600px;
    }
`;

export const ImageWrapper = styled.div`
    position: absolute;
    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 
      0 0 0 1px #d4d4d5;
    margin-left: 2rem;

    @media (min-width: 1400px) {
        min-width: 55%;
    }

    @media (min-width: 1300px) and (max-width: 1399px) {
        min-width: 55%;
    }

    @media (min-width: 1200px) and (max-width: 1299px) {
        min-width: 55%;
    }

    @media (min-width: 1100px) and (max-width: 1199px) {
      min-width: 55%;
    }

    @media (min-width: 991px) and (max-width: 1099px) {
      min-width: 65%;
    }

    @media (max-width: 990px) {
        min-width: 100%;
        margin: 0;
    }
`;

export const ContentWrapper = styled.div`
    position: absolute;
    border-radius: .28571429rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 
      0 0 0 1px #d4d4d5;
    background-color: white;
    padding: 15px;
    font-size: 20px;

    @media (min-width: 1400px) {
        width: 50%;
        margin-top: 50px;
        left: 675px;
    }

    @media (min-width: 1300px) and (max-width: 1399px) {
        width: 50%;
        margin-top: 50px;
        left: 625px;
    }

    @media (min-width: 1200px) and (max-width: 1299px) {
        width: 50%;
        margin-top: 50px;
        left: 550px;
    }

    @media (min-width: 1100px) and (max-width: 1199px) {
        width: 50%;
        margin-top: 50px;
        left: 525px;
    }

    @media (min-width: 991px) and (max-width: 1099px) {
      width: 55%;
      margin-top: 50px;
      left: 425px;
    }

    @media (min-width: 768px) and (max-width: 990px) {
      width: 100%;
      margin-top: 750px;
    }

    @media (min-width: 415px) and (max-width: 767px) {
      width: 100%;
      margin-top: 750px;
    }

    @media (min-width: 376px) and (max-width: 414px) {
      width: 100%;
      margin-top: 750px;
    }

    @media (max-width: 375px) {
        width: 100%;
        margin-top: 730px;
        
    }
`;

export const ImageTextOverlayWrapper = styled.section`
  z-index: 999;
  position: absolute;
  text-align: center;
  margin-left: 2rem;
  padding: 15px 40px 15px 40px;
  background-image: url(${BannerBG}),
    linear-gradient(35deg, rgba(99, 23, 25, 0.7) 0%, rgba(176, 58, 62, 0.7) 100%);

  @media (min-width: 1400px) {
    top: 590px;
    width: 48%;
  }

  @media (min-width: 1300px) and (max-width: 1399px) {
    top: 530px;
    width: 46%;
  }

  @media (min-width: 1200px) and (max-width: 1299px) {
    top: 380px;
    width: 45%;
  }

  @media (min-width: 1025px) and (max-width: 1199px) {
    top: 325px;
    width: 45%;
  }

  @media (min-width: 991px) and (max-width: 1024px) {
    top: 405px;
    width: 40%;
  }

  @media (min-width: 768px) and (max-width: 990px) {
    top: 525px;
    margin-left: 0;
  }

  @media (min-width: 600px) and (max-width: 767px) {
    top: 400px;
    margin-left: 0;
  }

  @media (min-width: 415px) and (max-width: 599px) {
    top: 250px;
    margin-left: 0;
  }
  
  @media (min-width: 376px) and (max-width: 414px) {
    top: 450px;
    margin-left: 0;
  }

  @media (min-width: 321px) and (max-width: 375px) {
    top: 410px;
    margin-left: 0;
  }

  @media (max-width: 320px) {
    top: 350px;
    margin-left: 0;
  }

  .force-italic-font {
    font-style: italic;
  }
`;
