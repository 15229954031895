import React, { Fragment } from 'react'
import { ThemeProvider } from 'styled-components'
import Sticky from 'react-stickynode'

import { raveniiTheme } from '../theme/ravenii'
import { ResetCSS } from '../assets/css/style'
import { GlobalStyle, ContentWrapper } from '../components/layout/container.style'

import { DrawerProvider } from '../components/common/contexts/DrawerContext'
import Seo from '../components/seo'
import Navbar from '../components/layout/Navbar'
import AboutUsLandingSection from '../components/containers/AboutUsLanding'
import ContactUsSection from '../components/containers/ContactUs'
import Footer from '../components/layout/Footer'

const AboutPage = () => {
  return (
    <ThemeProvider theme={raveniiTheme}>
      <Fragment>
        <Seo title='RAVENii | About Us' />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass='sticky-nav-active'>
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>

          <AboutUsLandingSection />
          <ContactUsSection />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  )
}

export default AboutPage
